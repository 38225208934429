import AWS from 'aws-sdk'

class DDBClient {
  constructor() {
    this.dynamoDB = new AWS.DynamoDB.DocumentClient({
      region: process.env.AWS_COGNITO_REGION,
      ...(process.env.AWS_ACCESS_KEY_ID
        ? { accessKeyId: process.env.AWS_ACCESS_KEY_ID }
        : {}),
      ...(process.env.AWS_SECRET_ACCESS_KEY
        ? { secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY }
        : {}),
      ...(process.env.AWS_SESSION_TOKEN
        ? { sessionToken: process.env.AWS_SESSION_TOKEN }
        : {})
    })
  }

  async query(params) {
    return this.dynamoDB.query(params).promise()
  }

  async scan(params) {
    return this.dynamoDB.scan(params).promise()
  }

  async update(params) {
    return this.dynamoDB.update(params).promise()
  }

  async delete(params) {
    return this.dynamoDB.delete(params).promise()
  }

  async batchGet(params) {
    return this.dynamoDB.batchGet(params).promise()
  }
}

export default new DDBClient()
